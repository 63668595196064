<script>
	import { v4 } from 'uuid';
	import { onMount, tick, createEventDispatcher } from 'svelte';
	import { debounce } from '$lib/js/function_utils';
	import { getStringDistance } from '$lib/js/jaro_winkler_utils';

	import Badge from '../Badges/Badge.svelte';
	const dispatch = createEventDispatcher();

	export let id = v4();
	export let label = undefined;
	export let items = [];
	export let labelFieldName = undefined;
	export let valueFieldName = undefined;
	export let placeholder = 'Search...';
	export let allowNonItem = false;
	export let required = false;
	export let disabled = false;
	export let pretext = undefined;
	export let usePretextSlot = undefined;
	export let showClear = true;
	export let fullWidth = true;

	export let text = '';
	export let selectedItem = null;
	export let value = null;

	let inputRef;
	let optionsRef;

	let filteredItems = [];
	let isOpen = false;
	let highlightedIndex = -1;

	onMount(() => {
		filteredItems = items;
	});

	$: setValue(value);

	export const setValue = (newValue) => {
		if (!newValue) {
			text = '';
			selectedItem = null;
			value = null;
			return;
		}

		const match = items.find((item) => item[valueFieldName] === newValue || item === newValue);

		if (!match && allowNonItem) {
			text = newValue;
			value = newValue;
			return;
		}

		handleSelect(match);
	};

	export const clearValue = () => {
		setValue(undefined);
	};

	export const getValue = () => {
		return value;
	};

	export const openAndFocus = () => {
		inputRef?.focus();
		setTimeout(() => {
			isOpen = true;
		}, 50);
	};

	async function filterItems(value) {
		text = value;

		// Use regular for loop during filtering for performance

		if (typeof items[0] === 'string') {
			let _f = [];

			for (var i = 0; i < items.length; i++) {
				if (items[i]?.toLowerCase()?.includes(value?.toLowerCase())) {
					_f.push(items[i]);
				}
			}

			filteredItems = _f.sort((a, b) => {
				const aDistance = getStringDistance(a, value);
				const bDistance = getStringDistance(b, value);
				return bDistance - aDistance;
			});

			// filteredItems = items?.filter((x) => x)?.filter((item) => item?.toLowerCase()?.includes(text?.toLowerCase()));
		} else {
			let _f = [];

			for (var z = 0; z < items.length; z++) {
				if (items[z]?.[labelFieldName]?.toLowerCase()?.includes(value?.toLowerCase())) {
					_f.push(items[z]);
				}
			}

			filteredItems = _f.sort((a, b) => {
				const aDistance = getStringDistance(a[labelFieldName], value);
				const bDistance = getStringDistance(b[labelFieldName], value);
				return bDistance - aDistance;
			});

			// filteredItems = _f;
			// filteredItems = items
			// 	?.filter((x) => x)
			// 	?.filter((item) => item?.[labelFieldName]?.toLowerCase()?.includes(text?.toLowerCase()));
		}

		highlightedIndex = -1;
	}

	function handleFocus() {
		isOpen = true;
		filteredItems = items;
		dispatch('focus', { id, text, selectedItem, value });
	}

	async function handleBlur() {
		await tick();

		let _text = text;

		if (
			!selectedItem ||
			(typeof selectedItem === 'string' && selectedItem !== text) ||
			(typeof selectedItem === 'object' && selectedItem[labelFieldName] !== text)
		) {
			const foundItem = items.find((item) => {
				if (typeof item === 'string') {
					return item.toLowerCase() === text.toLowerCase();
				} else {
					if (!item[labelFieldName]) return false;
					return item[labelFieldName].toLowerCase() === text.toLowerCase();
				}
			});

			if (foundItem) {
				handleSelect(foundItem);
			} else if (allowNonItem) {
				text = _text;
				selectedItem = _text;
				value = _text;
			} else {
				text = '';
				selectedItem = null;
				value = null;
			}
		}

		await tick();
		dispatch('blur', { id, text, selectedItem, value });
		debouncedDispatchChange();

		setTimeout(() => {
			isOpen = false;
		}, 150);
	}

	function handleSelect(item) {
		selectedItem = item;

		if (!item) {
			text = '';
			value = null;
			return;
		}

		if (typeof item === 'string') {
			text = item;
			value = item;
		} else {
			text = item[labelFieldName];
			value = item[valueFieldName];
		}

		isOpen = false;
		inputRef?.blur();

		setTimeout(async () => {
			debouncedDispatchChange();
		}, 25);
	}

	async function handleChange() {
		debouncedDispatchChange();
	}

	let prevValue = null;

	const dispatchChange = async () => {
		await tick();
		if (prevValue !== value) {
			dispatch('change', { id, text, selectedItem, value });
			prevValue = value;
		}
	};

	const debouncedDispatchChange = debounce(dispatchChange, 100);

	const handleClear = async () => {
		text = '';
		selectedItem = null;
		value = null;
		inputRef?.blur();

		await tick();
		dispatch('clear', { id, text, selectedItem, value });
		dispatch('change', { id, text, selectedItem, value });
	};

	function handleKeyDown(e) {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (highlightedIndex < filteredItems.length - 1) {
				highlightedIndex++;
				scrollToView();
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (highlightedIndex > 0) {
				highlightedIndex--;
				scrollToView();
			}
		} else if (e.key === 'Enter' || e.key === 'Tab') {
			e.preventDefault();
			if (highlightedIndex >= 0) {
				console.log('handleKeyDown() - handleSelect', { highlightedIndex, filteredItems });
				handleSelect(filteredItems[highlightedIndex]);
			} else {
				inputRef.blur();
			}
		} else if (e.key === 'Escape') {
			e.preventDefault();
			text = '';
			isOpen = false;
		}
	}

	function scrollToView() {
		if (optionsRef) {
			const listItem = optionsRef.children[highlightedIndex];
			if (listItem) {
				listItem.scrollIntoView({ block: 'nearest' });
			}
		}
	}

	const wrapSubstringWithSpan = (inputString, substring) => {
		if (!inputString?.length || !substring?.length) {
			return inputString;
		}

		const escapedSubstring = substring?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const regex = new RegExp(escapedSubstring, 'gi');
		return inputString?.replace(regex, (match) => `<b>${match}</b>`);
	};

	// Debounced functions

	const debouncedFilterItems = debounce(filterItems, 100);
</script>

<div class="form-group" class:w-full={fullWidth}>
	{#if label}
		<label for={id} class="block text-sm font-medium">
			{label}
			{#if required}<span class="text-red-500">*</span>{/if}
		</label>
	{/if}
	<div class="{label ? 'mt-1' : ''} ">
		<div class="search-dropdown relative" class:w-full={fullWidth}>
			<div class="z-2 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 text-center text-gray-500">
				{#if pretext}
					{pretext}
				{:else if usePretextSlot}
					<slot />
				{/if}
			</div>
			<input
				type="text"
				bind:this={inputRef}
				bind:value={text}
				on:input={(e) => debouncedFilterItems(e.target.value)}
				on:focus={handleFocus}
				on:blur={handleBlur}
				on:keydown={handleKeyDown}
				on:change={handleChange}
				class={$$props.class ?? ''}
				class:form-input={!disabled && !pretext && !usePretextSlot}
				class:form-input-with-pretext={pretext || usePretextSlot}
				class:with-pretext={pretext || usePretextSlot}
				class:form-input-disabled={disabled}
				class:cursor-not-allowed={disabled}
				autocomplete="off"
				{id}
				{placeholder}
				{required}
				{disabled}
			/>
			{#if showClear}
				<span on:click={() => !disabled && handleClear()} class="autocomplete-clear-button">
					<svg
						class="mt-1 h-4 w-4 text-gray-400 sm:mt-0.5"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"
						/>
					</svg>
				</span>
			{/if}
			{#if isOpen}
				<ul class="options" bind:this={optionsRef}>
					{#each filteredItems as item, index}
						{@const label = typeof item === 'string' ? item : item?.[labelFieldName]}
						{@const wrapped = wrapSubstringWithSpan(label, text)}
						<li
							class:highlighted={highlightedIndex === index}
							on:pointerenter={() => (highlightedIndex = index)}
							on:click|preventDefault|stopPropagation={() => {
								handleSelect(item);
							}}
						>
							<div class="flex flex-row gap-2">
								<div class="">
									{@html wrapped}
								</div>
								{#if item?.badge}
									<div class="grid content-center">
										<Badge color={item?.badge?.color ?? 'gray'} sizeClass="text-xs" padding-class="p-1">
											{item?.badge?.text}
										</Badge>
									</div>
								{/if}
								{#if item?.badges}
									{#each item?.badges ?? [] as badge (badge.text)}
										<div class="grid content-center">
											<Badge color={badge?.color ?? 'gray'} sizeClass="text-xs" padding-class="p-1">
												{badge?.text}
											</Badge>
										</div>
									{/each}
								{/if}
							</div>
						</li>
					{/each}
				</ul>
			{/if}
		</div>
	</div>
</div>

<style>
	.search-dropdown {
		position: relative;
		display: inline-block;
	}
	.options {
		position: absolute;
		list-style: none;
		background-color: white;
		padding: 0;
		margin: 0;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		border: 1px solid #999;
		border-radius: 8px;
		width: 100%;
		max-height: 200px;
		overflow-y: auto;
		z-index: 999;
	}
	.options li {
		padding: 10px 15px;
		color: #333;
		z-index: 100;
		cursor: pointer;
		line-height: 1.2;
	}
	.options li.highlighted {
		background-color: #2e69e2;
		border-radius: 4px;
		color: #fff;
	}
	.autocomplete-clear-button {
		cursor: pointer;
		display: block;
		text-align: center;
		position: absolute;
		right: 0.1em;
		padding: 0.3em 0.6em;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		/* z-index: 4; */
	}
</style>
